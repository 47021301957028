import * as gql from '@/graphql'
import { useLazyQuery } from '@vue/apollo-composable'
import { ref } from 'vue'
type ParamsInpt = {
  startDate: string
  endDate: string
  funditems: string[]
  pageNo: number
}
export default function useAllHisOrders (params: ParamsInpt) {
  const allHisOrderList = ref()
  const { loading, onResult, load } = useLazyQuery(gql.lpoaGetHisAyersOrders, {
    filter: params
  })
  onResult(res => {
    const { lpoaGetHisAyersOrders, lpoaManagersBindInfoList } = res.data
    allHisOrderList.value = lpoaGetHisAyersOrders.data.rows.map(
      (item: { [x: string]: string }) => {
        item.entrustBs = item.entrustBs === '1' ? '买入' : '卖出'
        item.stock = `${item.stockName} ${item.stockCode}.${item.exchangeType}`
        item.businessDate = item.tradeDate + ' ' + item.businessTime
        item.businessAmount = Number(item.businessAmount).toLocaleString()
        item.businessBalance = Number(item.businessBalance).toLocaleString()
        item.nameCn =
          lpoaManagersBindInfoList.data[0]?.bindInfo?.filter(
            (ele: any) => ele?.account === item?.fundAccount
          )[0]?.nameCn ?? '--'
        return item
      }
    )
  })
  return {
    loadAll: load,
    allHisOrderList,
    exportLoading: loading
  }
}
