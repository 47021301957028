import { ORDER_CANCEL, ORDER_UPDATE } from '@/constant'
import { EntrustStatus } from '@/constant/enum'

export const setDisabled = (list: any, headList: any, index: number) => {
  if (index === 0) {
    list.value = list.value?.map(
      (item: { disabled: boolean, entrustStatus: EntrustStatus }) => {
        return {
          ...item,
          disabled: !ORDER_UPDATE.includes(item.entrustStatus)
        }
      }
    )
  } else {
    list.value = list.value?.map(
      (item: { disabled: boolean, entrustStatus: EntrustStatus }) => {
        return {
          ...item,
          disabled: !ORDER_CANCEL.includes(item.entrustStatus)
        }
      }
    )
  }
  headList = headList.map((t: any) => {
    const isDisabled = list.value?.some((item: any) => item.disabled)
    if (isDisabled) {
      t.disabled = true
    }
    return {
      ...t
    }
  })
}
