export const tHeaderList = Object.freeze([
  {
    type: 'selection',
    prop: 'selectStatus',
    width: '50',
    label: ''
  },
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '' },
  { label: '客户账户', prop: 'fundAccount', type: '', width: '' },
  { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: '股票名称/代码',
    prop: 'stock',
    type: 'stock',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '委托价格', prop: 'entrustPrice', type: 'permillage', digit: 3 },
  {
    label: '委托数量',
    prop: 'entrustAmount',
    type: 'permillage',
    digit: 0
  },
  { label: '成交价格', prop: 'businessPrice', type: 'permillage', digit: 3 },
  { label: '成交数量', prop: 'businessAmount', type: 'permillage', digit: 0 },
  { label: '交易状态', prop: 'entrustStatus', type: 'status', width: '' },
  { label: '订单类型', prop: 'entrustProp', type: 'entrustProp', width: '' },
  { label: '订单号', prop: 'orderTxnReference', type: '', width: '' },
  { label: '下单时间', prop: 'tradeTime', type: 'time', width: '' }
  // {
  //   label: '操作',
  //   prop: 'entrustStatus',
  //   type: 'otc',
  //   width: ''
  // }
])
export const hisHeaderList = Object.freeze([
  { label: '方向', prop: 'entrustBs', type: 'entrustBs', width: '' },
  { label: '客户账户', prop: 'fundAccount', type: '', width: '' },
  { label: '客户', prop: 'nameCn', type: '', width: '' },
  {
    label: '股票名称/代码',
    prop: 'stock',
    type: 'twoLinesNewsLines',
    width: '220px',
    propList: [
      { prop: 'stockName', icon: 'exchangeType', class: 'lines-stock-name' },
      { prop: 'stockCode', class: 'lines-stock-code' },
      { prop: 'exchangeType', class: 'lines-stock-type' }
    ]
  },
  { label: '成交价格', prop: 'businessPrice', type: 'price', width: '' },
  { label: '成交数量', prop: 'businessAmount', type: 'permillage', width: '' },
  { label: '成交金额', prop: 'businessBalance', type: 'permillage', width: '' },
  { label: '总成交费用', prop: 'fareTotal', type: 'permillage', width: '' },
  { label: '成交时间', prop: 'businessDate', type: '', width: '' },
  { label: '订单号', prop: 'orderTxnReference', type: '', width: '' }
])
