import { exportJson2Excel } from '@/utils/excel'
import { ElMessage } from 'element-plus'

export default function useExportTable (
  thList: any,
  list: any,
  name: string
) {
  const filterList = thList?.filter(
    (item: { type: string }) => item.type !== 'setting'
  )
  const tHeaderList = filterList?.map((item: { label: string }) => item.label)
  const keyList = filterList?.map((item: { prop: string }) => item.prop)
  const formatJson = (filterKeys: any, jsonData: any) =>
    jsonData?.map((data: any) =>
      filterKeys?.map((key: string) => {
        return data[key]
      })
    )
  const data = formatJson(keyList, list)
  exportJson2Excel(tHeaderList, data, name, undefined, undefined, true, 'xlsx')
  ElMessage.success({
    message: '导出成功',
    type: 'success',
    customClass: 'custom-success'
  })
}
