import * as gql from '@/graphql'
import { useQuery } from '@vue/apollo-composable'
import { ref } from 'vue'
type ParamsInpt = {
  startDate: string
  endDate: string
  funditems: string[]
  pageNo: number
  pageSize: number
}
export default function useHisAyersOrders (params: ParamsInpt) {
  const hisTotal = ref()
  const hisOrderList = ref()
  const { loading, refetch, onResult } = useQuery(gql.lpoaGetHisAyersOrders, {
    filter: params
  })
  onResult(res => {
    const { lpoaGetHisAyersOrders, lpoaManagersBindInfoList } = res?.data
    hisOrderList.value = lpoaGetHisAyersOrders?.data?.rows?.map(
      (item: { [x: string]: string }) => {
        item.businessDate = item.tradeDate + ' ' + item.businessTime
        item.nameCn =
          lpoaManagersBindInfoList?.data[0]?.bindInfo?.filter(
            (ele: any) => ele?.account === item?.fundAccount
          )[0]?.nameCn ?? '--'
        return item
      }
    )
    hisTotal.value = lpoaGetHisAyersOrders?.data?.totalCount
  })
  return {
    hisOrderList,
    hisTotal,
    refetchHisOrders: refetch,
    hisLoading: loading
  }
}
