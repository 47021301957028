
import { defineComponent } from 'vue'
import { EntrustBs } from '@/constant/enum'
import { STATES, ENTRUSTPROPMAP } from '@/constant'
import { numFormat } from '@/utils/num_format'
import { mktClass } from '@/utils/color'

export default defineComponent({
  name: 'CancelOrder',
  props: {
    dialogVisible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    data: { type: Array, default: [] } as any
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const confirm = () => {
      emit('update:dialogVisible', false)
      emit('confirm')
    }
    const close = () => {
      emit('update:dialogVisible', false)
    }
    return {
      confirm,
      close,
      EntrustBs,
      numFormat,
      STATES,
      ENTRUSTPROPMAP,
      mktClass
    }
  }
})
