import * as gql from '@/graphql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { ElMessage } from 'element-plus'
import { computed, nextTick, reactive, ref } from 'vue'
import { tHeaderList } from '../data'
import { ORDER_CANCEL, ORDER_UPDATE } from '@/constant'
import * as crypoto from '@/utils/crypto'
import { useRouter } from 'vue-router'
import { EntrustStatus } from '@/constant/enum'
import { setDisabled } from '@/utils/order'

type ParamsInpt = {
  funditems: string[]
  pageNo: number
  pageSize: number
}
export default function useTodayAyersOrders (params: ParamsInpt) {
  const router = useRouter()
  const todayOrderList = ref()
  const total = ref(0)
  const orderData = ref()
  const state2 = reactive({
    dialogVisible: false,
    list: [],
    actionIndex: 3,
    options: [
      { name: '改单', value: 0 },
      { name: '撤单', value: 1 }
    ],
    tHeaderList,
    doneFlag: false,
    cancelLoading: false
  })
  const {
    loading,
    refetch: refetchTodayOrders,
    onResult
  } = useQuery(gql.lpoaGetTodayAyersOrders, {
    filter: params
  })
  onResult(res => {
    const { lpoaGetTodayAyersOrders, lpoaManagersBindInfoList } = res.data
    total.value = lpoaGetTodayAyersOrders.data.totalCount
    todayOrderList.value = lpoaGetTodayAyersOrders.data.rows.map(
      (item: any) => {
        return {
          ...item,
          tradeTime: item.orderDetail.filter(
            (item: { title: string }) => item.title === '下单时间'
          )[0].value,
          businessPrice: item.orderDetail.filter(
            (item: { title: string }) => item.title === '成交均价'
          )[0].value,
          nameCn:
            lpoaManagersBindInfoList.data[0]?.bindInfo.filter(
              (ele: any) => ele.account === item.fundAccount
            )[0]?.nameCn ?? '--'
        }
      }
    )
    setDisabled(todayOrderList, state2.tHeaderList, state2.actionIndex)
  })
  const { mutate: cancelOrder, onDone } = useMutation(
    gql.cancelTodayAyersOrders
  )
  onDone(res => {
    const { cancelTodayAyersOrders } = res.data
    state2.doneFlag = true
    if (cancelTodayAyersOrders.ok) {
      // ElMessage({
      //   message: '撤单已提交',
      //   type: 'success',
      //   duration: 3000,
      //   customClass: 'custom-success'
      // })
      orderData.value = cancelTodayAyersOrders.data
      refetchTodayOrders()
    } else {
      ElMessage({
        message: cancelTodayAyersOrders.reason.desc,
        type: 'warning',
        duration: 1500,
        customClass: 'custom-warning'
      })
    }
    state2.cancelLoading = false
  })
  // 多选
  const orderQty = computed(
    () =>
      todayOrderList.value?.filter(
        (item: { selectStatus: boolean }) => item.selectStatus
      ).length
  )
  const clearSelect = () => {
    todayOrderList.value = todayOrderList.value?.map(
      (item: { disabled: boolean, entrustStatus: EntrustStatus }) => {
        return {
          ...item,
          disabled: !ORDER_CANCEL.includes(item.entrustStatus),
          selectStatus: false
        }
      }
    )
  }
  const confirmSelect = () => {
    if (!orderQty.value) return
    const list = todayOrderList.value.filter(
      (item: { selectStatus: boolean }) => item.selectStatus
    )
    if (state2.actionIndex === 0) {
      if (list.length > 1) {
        ElMessage({
          message: '改单只能选择一项',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      // 改单
      const {
        entrustBs,
        entrustProp,
        entrustPrice,
        extendedTrading,
        orderTxnReference
      } = list[0]
      const type = {
        entrustBs,
        entrustProp,
        entrustPrice,
        extendedTrading
      }
      router.push({
        name: 'BulkTrading',
        query: {
          bidAskList: crypoto.base64Encrypt(JSON.stringify(list)),
          type: JSON.stringify(type),
          orderid: orderTxnReference,
          ayers: 1
        }
      })
    } else {
      state2.dialogVisible = true
      state2.list = list
    }
  }
  // 确认撤单
  const confirmCancel = () => {
    state2.cancelLoading = true
    const orderInfo = state2.list.map((item: { [x: string]: string }) => {
      return {
        fundAccount: item.clientId,
        orderTxnReference: item.orderTxnReference
      }
    })
    cancelOrder({
      params: {
        orderInfo
      }
    })
  }
  const confirmResult = () => {
    state2.doneFlag = false
    nextTick(() => {
      refetchTodayOrders()
      state2.actionIndex = 3
    })
  }
  const showAction = computed(() =>
    todayOrderList.value?.some(
      (item: { entrustStatus: EntrustStatus }) =>
        ORDER_CANCEL.includes(item.entrustStatus) ||
        ORDER_UPDATE.includes(item.entrustStatus)
    )
  )
  // 取消
  const cancelSelect = () => {
    state2.actionIndex = 3
    clearSelect()
  }
  // 切换改单撤单
  const changeIndex = (index: number) => {
    state2.actionIndex = index
    clearSelect()
    setDisabled(todayOrderList, state2.tHeaderList, state2.actionIndex)
  }
  // 改单点击表格复选框 只能选择一项
  const changeSelect = (data: any, val: boolean) => {
    console.log(data, '当前选中项', val)
    if (state2.actionIndex === 0) {
      if (val) {
        todayOrderList.value = todayOrderList.value?.map(
          (item: {
            orderTxnReference: string
            selectStatus: boolean
            disabled: boolean
          }) => {
            if (data?.orderTxnReference === item?.orderTxnReference) {
              item.selectStatus = val
            } else {
              // item.disabled = true
              item.selectStatus = false
            }
          }
        )
      } else {
        nextTick(() => {
          clearSelect()
        })
      }
    }
  }
  return {
    todayOrderList,
    total,
    refetchTodayOrders,
    cancelOrder,
    todayLoading: loading,
    confirmResult,
    orderData,
    state2,
    clearSelect,
    confirmSelect,
    confirmCancel,
    orderQty,
    cancelSelect,
    changeIndex,
    changeSelect,
    showAction
  }
}
