
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { tHeaderList, hisHeaderList } from './data'
import useTodayAyersOrders from './hooks/useTodayAyersOrders'
import useHisAyersOrders from './hooks/useHisAyersOrders'
import useAllHisOrders from './hooks/useAllHisOrders'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'
import CanceOrder from './components/cancelOrder.vue'
import useExportTable from '@/hooks/useExportTable'

export default defineComponent({
  name: 'OTC',
  components: {
    CanceOrder
  },
  setup() {
    const state = reactive({
      activeIndex: 0,
      activeName: '0',
      orderList: [],
      date: '',
      timeOptions: [
        {
          name: '近一个月',
          value: 0
        },
        {
          name: '近半年',
          value: 1
        },
        {
          name: '近一年',
          value: 2
        },
        {
          name: '自定义',
          value: 3
        }
      ],
      params: {
        funditems: [],
        pageNo: 1,
        pageSize: 10
      },
      hisParams: {
        funditems: [],
        startDate: dayjs().subtract(1, 'month').unix().toString(),
        endDate: dayjs().unix().toString(),
        pageNo: 1,
        pageSize: 10
      },
      fundAccount: ''
    })
    const {
      showAction,
      orderQty,
      state2,
      changeIndex,
      changeSelect,
      confirmSelect,
      confirmCancel,
      orderData,
      confirmResult,
      todayLoading,
      cancelSelect,
      refetchTodayOrders,
      todayOrderList,
      total
    } = useTodayAyersOrders(state.params)
    const { hisLoading, refetchHisOrders, hisOrderList, hisTotal } =
      useHisAyersOrders(state.hisParams)
    const handleClick = () => {
      if (state.activeName === '0') {
        refetchTodayOrders()
      } else {
        refetchHisOrders()
      }
    }
    const selectTime = (val: number) => {
      state.activeIndex = val
      switch (val) {
        case 0:
          state.hisParams.startDate = dayjs()
            .subtract(1, 'month')
            .unix()
            .toString()
          break
        case 1:
          state.hisParams.startDate = dayjs()
            .subtract(6, 'month')
            .unix()
            .toString()
          break
        case 2:
          state.hisParams.startDate = dayjs()
            .subtract(1, 'year')
            .unix()
            .toString()
          break
        default:
          break
      }
    }
    const changeDate = (val: any) => {
      state.hisParams.startDate = dayjs(val[0]).unix().toString()
      state.hisParams.endDate = dayjs(val[1]).unix().toString()
    }
    const params = ref({
      pageNo: 1,
      startDate: '',
      endDate: '',
      funditems: []
    })
    const { loadAll, allHisOrderList, exportLoading } = useAllHisOrders(
      params.value
    )
    const exportTable = () => {
      params.value.startDate = state.hisParams.startDate
      params.value.endDate = state.hisParams.endDate
      loadAll()
      setTimeout(() => {
        if (!exportLoading.value) {
          console.log('导出', allHisOrderList.value)
          useExportTable(
            hisHeaderList,
            allHisOrderList.value,
            '柜台交易历史成交'
          )
        }
      }, 1500)
    }
    const tHeadList = tHeaderList.filter(
      (item: { type: string }) => item.type !== 'selection'
    )
    const handleSizeChange = (val: number) => {
      console.log(`${val} items per page`)
      state2.tHeaderList =
        showAction.value &&
        (state2.actionIndex === 0 || state2.actionIndex === 1)
          ? tHeaderList
          : tHeadList
    }
    const handleCurrentChange = (val: number) => {
      console.log(`current page: ${val}`)
      state2.tHeaderList =
        showAction.value &&
        (state2.actionIndex === 0 || state2.actionIndex === 1)
          ? tHeaderList
          : tHeadList
    }
    watch(
      () => [showAction.value, state2.actionIndex],
      () => {
        // 初始进入不选中
        if (!showAction.value) {
          state2.actionIndex = 3
        }
        state2.tHeaderList =
          showAction.value &&
          (state2.actionIndex === 0 || state2.actionIndex === 1)
            ? tHeaderList
            : tHeadList
      },
      {
        immediate: true
      }
    )
    return {
      ...toRefs(state),
      ...toRefs(state2),
      handleClick,
      todayOrderList,
      total,
      handleSizeChange,
      handleCurrentChange,
      selectTime,
      changeDate,
      exportTable,
      hisTotal,
      hisHeaderList,
      hisOrderList,
      exportLoading,
      todayLoading,
      hisLoading,
      showAction,
      orderQty,
      confirmSelect,
      cancelSelect,
      changeIndex,
      changeSelect,
      confirmResult,
      confirmCancel,
      orderData
    }
  }
})
